<template>
  <div>
    <b-card>
      <div class="app_title_box">
        <h4>Review List</h4>
        <!-- <b-button
          v-ripple.400="'rgba(113, 102, 240, 0.15)'"
          variant="outline-primary"
          @click="showModal"
        >
          New
        </b-button> -->
      </div>
      <div class="mb-2">
        <b-row>
          <b-col cols="3">
            <b-form-group
              label="Search by keyword"
              label-for="v-search-keyword"
            >
              <b-form-input
                id="h-search-keyword"
                v-model="filter"
                type="text"
                placeholder="search here..."
              />
            </b-form-group>
          </b-col>
        </b-row>
      </div>
      <b-table
        responsive="sm"
        :items="reviewItems"
        :fields="user_type === 'member' ? fields : staff_fields"
        striped
        sort-by.sync="sortBy"
        :sort-desc.sync="sortDesc"
        :filter="filter"
        :filter-included-fields="filterOn"
        :per-page="perPage"
        :current-page="currentPage"
        @row-clicked="showDetails"
      >
        <template #cell(cover)="data">
          <b-avatar :src="getImage(data.item.business_profile.cover)" />
        </template>
        <template #cell(business_name)="data">
          {{ data.value }}
        </template>
        <template #cell(rating)="data">
          <b-form-rating
            variant="warning"
            no-border
            :value="data.value"
            readonly
            inline
            class="raiting_box"
            style="border:none !important; padding-left: 0 !important;"
          />
        </template>
        <template
          v-if="user_type !== 'member'"
          #cell(status)="data"
        >

          <!-- <b-badge :variant="data.value === 'reported' ? 'danger' : 'success'">
            {{ data.value }}
          </b-badge> -->

          <b-badge
            :variant="data.value === 'active' ? 'light-success':
              data.value === 'reported' ? 'light-danger' : data.value === 'under review' ? 'light-warning' : 'light-secondary'
            "
          >
            {{ data.value }}
          </b-badge>
          <!-- <b-form-checkbox
            :checked="data.value === true ? 'true' : 'false'"
            class="custom-control-success"
            name="check-button"
            switch
            @change="toggleReview($event, data.item)"
          >
            <span class="switch-icon-left">
              <feather-icon icon="CheckIcon" />
            </span>
            <span class="switch-icon-right">
              <feather-icon icon="XIcon" />
            </span>
          </b-form-checkbox> -->
        </template>
      </b-table>
      <b-pagination
        v-model="currentPage"
        :total-rows="totalRows"
        :per-page="perPage"
        align="center"
        size="lg"
        class="my-0"
      />
    </b-card>

    <b-modal
      id="app-modal"
      :title="is_update ? 'Uptate' : 'Add New'"
      hide-footer
      size="huge"
      @close="hide"
    >
      <b-card-text>
        <div class="bp_form_box">
          <div class="bp_form_side_bar">

            <div
              v-for="(data, index) in tab_items"
              :key="index"
              class="bp_tab_link"
              :class="is_tab_active === data.id ? 'bp_active' : ''"
              @click="showFormContent(data.id)"
            >
              <div class="bp_tab_counter">
                {{ index + 1 }}
              </div>
              <div class="bp_text_view">
                <span> {{ data.title }} </span>
              </div>
            </div>

          </div>
          <div class="bp_form_view">
            <div class="form_content">

              <div
                v-if="review !== null && is_tab_active === 1"
                class="review__card"
              >
                <div class="review_status_view mb-2">
                  <div class="review_profile_view">
                    <b-avatar
                      :src="getImage(review.business_profile && review.business_profile.cover)"
                      size="100"
                    />
                    <div class="profile_details">
                      <h5>{{ review.business_profile && review.business_profile.name }}</span></h5>
                      <div class="raiting_stars">
                        <b-form-rating
                          v-model="review.rating"
                          variant="warning"
                          inline
                          no-border
                          readonly
                          class="raiting_box"
                        />
                      </div>
                    </div>
                  </div>

                  <!-- <b-badge
                :variant="review.status === 'active' ? 'light-success':
                  review.status === 'reported' ? 'light-danger' : review.status === 'under review' ? 'light-warning' : 'light-secondary'
                "
              >
                {{ review.status === 'reported' ? 'Review reported' : 'Review ' + review.status }}
              </b-badge> -->
                </div>
                <b-badge
                  v-if="review.status === 'reported'"
                  :variant="review.status === 'active' ? 'light-success':
                    review.status === 'reported' ? 'light-danger' : review.status === 'under review' ? 'light-warning' : 'light-secondary'
                  "
                >
                  {{ review.status === 'reported' ? 'Review reported' : 'Review ' + review.status }}
                </b-badge>
                <p class="review_display_text mt-1">
                  {{ review.review | setTruncate(350, "...") }}
                </p>
                <div
                  class="extra_review_content"
                >
                  <div class="row mb-1">
                    <div class="col-md-4 review-content">
                      <feather-icon
                        icon="ChevronsRightIcon"
                        class="mr-1"
                      />
                      <span class="review-content-heading">Customer Service</span>
                    </div>
                    <div class="col-md-8">
                      {{ review.content.customer_service }}
                    </div>
                  </div>
                  <div class="row mb-1">
                    <div class="col-md-4 review-content">
                      <feather-icon
                        icon="ChevronsRightIcon"
                        class="mr-1"
                      />
                      <span class="review-content-heading">Pricing</span>
                    </div>
                    <div class="col-md-8">
                      {{ review.content.pricing }}
                    </div>
                  </div>
                  <div class="row mb-1">
                    <div class="col-md-4 review-content">
                      <feather-icon
                        icon="ChevronsRightIcon"
                        class="mr-1"
                      />
                      <span class="review-content-heading">Timeliness</span>
                    </div>
                    <div class="col-md-8">
                      {{ review.content.timeliness }}
                    </div>
                  </div>
                  <div class="row mb-1">
                    <div class="col-md-4 review-content">
                      <feather-icon
                        icon="ChevronsRightIcon"
                        class="mr-1"
                      />
                      <span class="review-content-heading">Quality</span>
                    </div>
                    <div class="col-md-8">
                      {{ review.content.quality }}
                    </div>
                  </div>
                  <div class="row mb-1">
                    <div class="col-md-4 review-content">
                      <feather-icon
                        icon="ChevronsRightIcon"
                        class="mr-1"
                      />
                      <span class="review-content-heading">Recommendation</span>
                    </div>
                    <div class="col-md-8">
                      {{ review.content.recommend }}
                    </div>

                    <!-- <div class="col-md-12">
                  <b-button
                    v-if="userData.user_info.user_type !== 'staff' && userData.user_info.user_type !== 'manager' && review.reviewer.user.id !== userData.user_info.id"
                    v-ripple.400="'rgba(234, 84, 85, 0.15)'"
                    variant="outline-success"
                    class="mt-2"
                    @click="showReportReview"
                  >
                    Report Review
                  </b-button>
                </div> -->
                  </div>
                </div>
              </div>

              <div v-if="is_tab_active === 2">
                <h4 class="mb-2">
                  Review Status
                </h4>
                <b-form-select
                  v-model="selected = review.status"
                  :options="options"
                  style="width: 100%"
                  @change="statusChange($event, review)"
                />
              </div>
            </div>
          </div>

        </div>

        <!-- <div
          v-if="is_customer_service"
          class="review_content"
        >
          <h4 class="mb-1">
            Customer Service
          </h4>
          <p class="mb-2">
            How would you rate the responsiveness of our customer service team?
          </p>
          <b-form-group label="">
            <b-form-radio-group
              v-model="content.customer_service"
              :options="customer_service"
              name="customer_service"
              class="app_radio_box"
              stacked
              @change="checkCustomerService"
            />
          </b-form-group>
        </div>

        <div
          v-if="is_quality_service"
          class="review_content"
        >
          <h4 class="mb-1">
            Quality of Service
          </h4>
          <p class="mb-2">
            How satisfied were you with the overall quality of service you received?
          </p>
          <b-form-group label="">
            <b-form-radio-group
              v-model="content.quality"
              :options="quality_service"
              name="quality_service"
              class="app_radio_box"
              stacked
              @change="checkQualityService"
            />
          </b-form-group>
        </div>

        <div
          v-if="is_money_value"
          class="review_content"
        >
          <h4 class="mb-1">
            Value for Money
          </h4>
          <p class="mb-2">
            Were you satisfied with the level of customer service provided for the price paid?
          </p>
          <b-form-group label="">
            <b-form-radio-group
              v-model="content.pricing"
              :options="money_value"
              name="money_value"
              class="app_radio_box"
              stacked
              @change="checkMoneyValue"
            />
          </b-form-group>
        </div>

        <div
          v-if="is_timeless"
          class="review_content"
        >
          <h4 class="mb-1">
            Timeliness
          </h4>
          <p class="mb-2">
            Was the service delivered on time?
          </p>
          <b-form-group label="">
            <b-form-radio-group
              v-model="content.timeliness"
              :options="timeless"
              name="timeless"
              class="app_radio_box"
              stacked
              @change="checkTimeliness"
            />
          </b-form-group>
        </div>
        <div
          v-if="is_recommend"
          class="review_content"
        >
          <h4 class="mb-1">
            Recommend
          </h4>
          <p class="mb-2">
            Would you recommend this service to others based on the quality of service you received?
          </p>
          <b-form-group label="">
            <b-form-radio-group
              v-model="content.recommend"
              :options="recommend"
              name="recommend"
              class="app_radio_box"
              stacked
              @change="checkRecommend"
            />
          </b-form-group>
        </div>

        <div
          v-if="is_rating_enable"
          class="review_content"
        >
          <h4 class="mb-1 text-center">
            Ratings
          </h4>
          <p class="text-center">
            How will you rate this service?
          </p>

          <div class="text-center">
            <feather-icon
              icon="FrownIcon"
              size="20"
              class="rating_emoji text-danger"
              @click="setRating"
            />
            <b-form-rating
              id="rating-lg"
              v-model="value"
              size="lg"
              class="raiting_box"
              inline
              variant="warning"
              @change="checkRating"
            />
          </div>
        </div>

        <div
          v-if="is_write_comment"
          class=""
        >
          <validation-observer ref="reviewForm">
            <validation-provider
              #default="{ errors }"
              name="comment"
              rules="required"
            >
              <b-form-textarea
                id="textarea-default"
                v-model="review_message"
                placeholder="Write some comment..."
                :state="errors.length > 0 ? false:null"
                rows="4"
              />
              <small class="text-danger">{{ errors[0] }}</small>
              <br>
            </validation-provider>
          </validation-observer>
          <b-button
            v-ripple.400="'rgba(113, 102, 240, 0.15)'"
            variant="outline-success"
            class="mt-2"
            @click="is_update ? updateReview() : ''"
          >
            {{ is_update ? 'Save Changes' : 'Post Review' }}
          </b-button>
        </div>

        <div
          v-if="is_update"
          class="edit-next-button"
        >
          <b-button
            v-if="edit_page > 0"
            v-ripple.400="'rgba(113, 102, 240, 0.15)'"
            variant="outline-success"
            class="mt-2"
            @click="prevPage"
          >
            <feather-icon icon="ArrowLeftIcon" />
          </b-button>
          <b-button
            v-if="edit_page < 6"
            v-ripple.400="'rgba(113, 102, 240, 0.15)'"
            variant="outline-success"
            class="mt-2"
            @click="nextPage"
          >
            <feather-icon icon="ArrowRightIcon" />
          </b-button>
        </div> -->
      </b-card-text>
    </b-modal>
  </div>
</template>

<script>
import {
  BCard, BButton, BModal, VBModal, BCardText, BTable, BFormRating, BFormCheckbox, BAvatar, BPagination, BFormInput,
  BRow, BCol, BFormGroup, BFormRadioGroup, BFormTextarea, BBadge, BFormSelect,
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required } from '@validations'
import Ripple from 'vue-ripple-directive'
import FrownIcon from '../../assets/images/icons/fown.png'

export default {
  components: {
    BCard,
    BButton,
    BModal,
    BCardText,
    BFormRating,
    BTable,
    BFormCheckbox,
    BBadge,
    BAvatar,
    BPagination,
    BFormInput,
    BRow,
    BCol,
    BFormGroup,
    BFormRadioGroup,
    BFormTextarea,
    ValidationProvider,
    ValidationObserver,
    BFormSelect,
  },
  directives: {
    Ripple,
    'b-modal': VBModal,
  },
  data() {
    return {
      is_update: false,
      staff_fields: ['cover', { key: 'business_name', label: 'Business Name', sortable: true }, 'review', { key: 'rating', label: 'Rating', sortable: true }, 'status'],
      fields: ['cover', { key: 'business_name', label: 'Business Name', sortable: true }, 'review', { key: 'rating', label: 'Rating', sortable: true }],
      perPage: 10,
      filter: null,
      sortBy: 'id',
      sortDesc: false,
      filterOn: [],
      pageOptions: [3, 5, 10],
      currentPage: 1,
      FrownIcon,
      selected: '',
      edit_page: 0,
      is_edit_mode: false,
      required,
      value: 0,
      business_profile_id: null,
      member_id: null,
      review_message: '',
      is_customer_service: true,
      is_quality_service: false,
      is_money_value: false,
      is_timeless: false,
      is_recommend: false,
      is_rating_enable: false,
      is_write_comment: false,
      review: null,
      user_type: '',
      tab_items: [
        { id: 1, title: 'Details' },
        { id: 2, title: 'Status' },
      ],
      is_tab_active: 1,
      options: [
        { value: 'active', text: 'active' },
        { value: 'canceled', text: 'canceled' },
        { value: 'reported', text: 'reported' },
        { value: 'under review', text: 'under review' },
      ],
      customer_service: [
        { text: 'Excellent', value: 'Excellent' },
        { text: 'Good', value: 'Good' },
        { text: 'Fair', value: 'Fair' },
        { text: 'Poor', value: 'Poor' },
        { text: 'Very poor', value: 'Very poor' },
      ],
      quality_service: [
        { text: 'Very satisfied', value: 'Very satisfied' },
        { text: 'Satisfied', value: 'Satisfied' },
        { text: 'Neutral', value: 'Neutral' },
        { text: 'Dissatisfied', value: 'Dissatisfied' },
        { text: 'Very dissatisfied', value: 'Very dissatisfied' },
      ],
      money_value: [
        { text: 'Yes, I was satisfied', value: 'Yes, I was satisfied' },
        { text: 'Somewhat satisfied', value: 'Somewhat satisfied' },
        { text: 'Neutral', value: 'Neutral' },
        { text: 'Somewhat dissatisfied', value: 'Somewhat dissatisfied' },
        { text: 'No, I was not satisfied:', value: 'No, I was not satisfied' },
      ],
      timeless: [
        { text: 'Yes, the service was delivered on time', value: 'Yes, the service was delivered on time' },
        { text: 'Somewhat on time', value: 'Somewhat on time' },
        { text: 'Neutral', value: 'Neutral' },
        { text: 'Somewhat late', value: 'Somewhat late' },
        { text: 'No, the service was not delivered on time', value: 'No, the service was not delivered on time' },
      ],
      recommend: [
        { text: 'Yes', value: 'Yes' },
        { text: 'No', value: 'No' },
      ],
      content: {
        customer_service: '', quality: '', pricing: '', recommend: '', timeliness: '',
      },
      review_id: null,
      is_reivew_content_show: [],
    }
  },
  computed: {
    reviews() {
      return this.$store.state.review.reviews
    },
    totalRows() {
      return this.reviews.length
    },
    reviewItems() {
      return this.reviews.map(item => ({
        ...item,
        business_name: `${item.business_profile.name}`,
      }))
    },
    userData() {
      return this.$store.state.auth.user
    },
  },
  mounted() {
    this.getReviews()
    this.user_type = localStorage.getItem('gts_user_type')
  },
  methods: {
    getReviews() {
      if (this.user_type === 'member') {
        this.$store.dispatch('review/getMemberReviews', this.userData.user_info.member.id)
      } else {
        this.$store.dispatch('review/getReviews')
      }
    },
    getImage(image) {
      if (image !== null || image !== '') {
        return process.env.VUE_APP_STORAGE_PATH + image
      }
      return image
    },
    showModal() {
      this.$bvModal.show('app-modal')
    },
    hide() {
      this.$bvModal.hide('app-modal')
      this.emptyData()
    },
    statusChange(status, item) {
      const data = { id: item.id, status }
      this.$store.dispatch('review/updateReviewStatus', data)
      const dom = this
      setTimeout(() => {
        dom.getReviews()
      }, 200)
    },
    emptyData() {
      this.value = 0
      this.review_message = ''
      this.is_customer_service = true
      this.is_quality_service = false
      this.is_money_value = false
      this.is_timeless = false
      this.is_rating_enable = false
      this.is_write_comment = false
      this.edit_page = 0
      this.content = {
        customer_service: '', quality_service: '', money_value: '', timeliness: '',
      }
    },
    showDetails(review) {
      // this.business_profile_id = review.business_profile_id
      // this.member_id = review.member_id
      // this.edit_page = 0
      // this.is_update = true
      // this.review_id = review.id
      // this.content.customer_service = review.content.customer_service
      // this.content.quality = review.content.quality
      // this.content.pricing = review.content.pricing
      // this.content.recommend = review.content.recommend
      // this.content.timeliness = review.content.timeliness
      // this.review_message = review.review
      // this.value = review.rating
      this.review = review
      this.showModal()
    },
    toggleReview(status, data) {
      const item = data
      item.status = status
      this.$store.dispatch('review/updateReview', item)
    },
    showFormContent(id) {
      this.is_tab_active = id
    },
    nextPage() {
      this.edit_page += 1
      if (this.edit_page === 0) {
        this.is_customer_service = false
        this.is_quality_service = true
        this.is_money_value = false
        this.is_timeless = false
        this.is_recommend = false
        this.is_rating_enable = false
        this.is_write_comment = false
      }

      if (this.edit_page === 1) {
        this.is_customer_service = false
        this.is_quality_service = false
        this.is_money_value = true
        this.is_timeless = false
        this.is_rating_enable = false
        this.is_write_comment = false
      }

      if (this.edit_page === 2) {
        this.is_customer_service = false
        this.is_quality_service = false
        this.is_money_value = false
        this.is_timeless = true
        this.is_rating_enable = false
        this.is_write_comment = false
      }

      if (this.edit_page === 3) {
        this.is_customer_service = false
        this.is_quality_service = false
        this.is_money_value = false
        this.is_timeless = false
        this.is_recommend = true
        this.is_rating_enable = false
        this.is_write_comment = false
      }

      if (this.edit_page === 4) {
        this.is_customer_service = false
        this.is_quality_service = false
        this.is_money_value = false
        this.is_timeless = false
        this.is_recommend = false
        this.is_rating_enable = true
        this.is_write_comment = false
      }

      if (this.edit_page === 5) {
        this.is_customer_service = false
        this.is_quality_service = false
        this.is_money_value = false
        this.is_timeless = false
        this.is_recommend = false
        this.is_rating_enable = false
        this.is_write_comment = true
      }
    },
    prevPage() {
      this.edit_page -= 1
      if (this.edit_page === 0) {
        this.edit_page = 0
        this.is_customer_service = true
        this.is_quality_service = false
        this.is_money_value = false
        this.is_timeless = false
        this.is_recommend = false
        this.is_rating_enable = false
        this.is_write_comment = false
      }

      if (this.edit_page === 1) {
        this.is_customer_service = false
        this.is_quality_service = true
        this.is_money_value = false
        this.is_timeless = false
        this.is_rating_enable = false
        this.is_write_comment = false
      }

      if (this.edit_page === 2) {
        this.is_customer_service = false
        this.is_quality_service = false
        this.is_money_value = true
        this.is_timeless = false
        this.is_rating_enable = false
        this.is_write_comment = false
      }

      if (this.edit_page === 3) {
        this.is_customer_service = false
        this.is_quality_service = false
        this.is_money_value = false
        this.is_timeless = true
        this.is_recommend = false
        this.is_rating_enable = false
        this.is_write_comment = false
      }

      if (this.edit_page === 4) {
        this.is_customer_service = false
        this.is_quality_service = false
        this.is_money_value = false
        this.is_timeless = false
        this.is_recommend = true
        this.is_rating_enable = false
        this.is_write_comment = false
      }

      if (this.edit_page === 5) {
        this.is_customer_service = false
        this.is_quality_service = false
        this.is_money_value = false
        this.is_timeless = false
        this.is_recommend = false
        this.is_rating_enable = true
        this.is_write_comment = false
      }
    },
    showReviewContent(id) {
      if (this.is_reivew_content_show.includes(id)) {
        this.is_reivew_content_show = this.is_reivew_content_show.filter(item => item !== id)
      } else {
        this.is_reivew_content_show.push(id)
      }
    },
    updateReview() {
      this.$refs.reviewForm.validate().then(success => {
        if (success) {
          const data = {
            id: this.review_id,
            business_profile_id: this.business_profile_id,
            member_id: this.member_id,
            rating: this.value,
            review: this.review_message,
            content: this.content,

          }
          this.$store.dispatch('businessProfile/updateReview', data)
          this.hide()
          this.edit_page = 0
          this.is_edit_mode = false
          const dom = this
          setTimeout(() => {
            dom.getReviews()
          }, 100)
        }
      })
    },

    checkCustomerService(item) {
      this.is_customer_service = false
      this.is_quality_service = true
      this.is_money_value = false
      this.is_timeless = false
      this.content.customer_service = item
      this.edit_page = 0
    },
    checkQualityService(item) {
      this.is_customer_service = false
      this.is_quality_service = false
      this.is_money_value = true
      this.is_timeless = false
      this.content.quality_service = item
      this.edit_page = 1
    },
    checkMoneyValue(item) {
      this.is_customer_service = false
      this.is_quality_service = false
      this.is_money_value = false
      this.is_timeless = true
      this.content.money_value = item
      this.edit_page = 2
    },
    checkTimeliness(item) {
      this.is_customer_service = false
      this.is_quality_service = false
      this.is_money_value = false
      this.is_timeless = false
      this.is_recommend = true
      this.content.timeliness = item
      this.edit_page = 3
    },
    checkRecommend(item) {
      this.is_customer_service = false
      this.is_quality_service = false
      this.is_money_value = false
      this.is_timeless = false
      this.is_recommend = false
      this.is_rating_enable = true
      this.content.recommend = item
      this.edit_page = 4
    },
    checkRating() {
      this.is_customer_service = false
      this.is_quality_service = false
      this.is_money_value = false
      this.is_timeless = false
      this.is_recommend = false
      this.is_rating_enable = false
      this.is_write_comment = true
      this.edit_page = 5
    },
    setRating() {
      this.is_customer_service = false
      this.is_quality_service = false
      this.is_money_value = false
      this.is_timeless = false
      this.is_rating_enable = false
      this.is_recommend = false
      this.value = 0
      this.is_write_comment = true
      this.edit_page = 5
    },
  },
}
</script>

<style>

</style>
